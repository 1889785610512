@tailwind base;
@tailwind components;
@tailwind utilities;

#root{
  height: 100%;
  padding: 0;
  margin: 0;
  inset: 0;
}

body{
  padding: 0;
  margin: 0;
  inset: 0;
}

@layer components {
    .default-text{
        @apply text-base xs:text-[17px] sm:text-lg duration-150 font-normal;
    }

    .default-heading{
        @apply lg:text-3xl sm:text-2xl;
    }

    .h-top{
        @apply 3xs:h-64 xs:h-80 md:h-96;
    }

    .hover-gradient{
      @apply hover:text-transparent bg-clip-text hover:bg-gradient-to-r from-magenta to-[#5800FF];
    }
}

.loader {
  margin: auto;
  border: 5px solid #EAF0F6;
  border-radius: 50%;
  border-top: 5px solid #E5007D;
  width: 34px;
  height: 34px;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes placeHolderShimmer {
    0% {
      background-position: -800px 0
    }
    100% {
      background-position: 800px 0
    }
  }

.animation{
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
}

.animation-darker{
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #e3e3e3 8%, #ababab 18%, #e1e1e1 33%);
    background-size: 800px 104px;
    position: relative;
}

.animation-magenta{
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #ef0987 8%, #b90066 18%, #E5007D 33%);
    background-size: 800px 104px;
    position: relative;
}

.background-masker {
    background-color: #fff;
    position: absolute;
  }

  .btn-divide-left {
    top: 0;
    height: 100%;
    width: 10px;
  }
